import { render, staticRenderFns } from "./integrate.html?vue&type=template&id=b4b0ae0a&scoped=true&"
import script from "./integrate.vue?vue&type=script&lang=js&"
export * from "./integrate.vue?vue&type=script&lang=js&"
import style0 from "./integrate.scss?vue&type=style&index=0&id=b4b0ae0a&lang=scss&scoped=true&"
import style1 from "./integrate.vue?vue&type=style&index=1&id=b4b0ae0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4b0ae0a",
  null
  
)

export default component.exports