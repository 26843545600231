<template src="./integrate.html"></template>
<style src="./integrate.scss" lang="scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters, companyStateRoutine } from '@/store/modules/company/routines'
import { getCompany } from '@/api/company'
import { setIntegrationStatusSkip } from '@/api/integrate'
import { deleteBankMandate } from '@/api/bankMandate'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import BankIntegrationBtnTrueLayer from '@/components/integrations/BankIntegrationBtnTrueLayer'
import JourneyHeader from '../journeyHeader'
import { toastDefaultOptions } from '../../../config/vue-toast'
import { amplitudeEvents } from '@/utils/constants'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent } from '@/utils/utils'
import { authGetters } from '@/store/modules/auth/routines'
import steps from '@/features/journey/steps'
import accountingIntegration from '@/mixins/accountingIntegration'
import ForwardAIIntegrationBtn from '@/components/integrations/ForwardAIIntegrationBtn'
import { forwardAIIntegrationRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'

export default {
  name: 'JourneyIntegrate',
  components: {
    BankIntegrationBtnTrueLayer,
    JourneyHeader,
    ForwardAIIntegrationBtn
  },
  mixins: [steps, accountingIntegration],
  data() {
    return {
      companyData: {
        companyName: null
      },
      bankMandateTimerId: null,
      amplitudeEvents,
      sendBasicEventToAmplitude,
      journeyStepsButtonAmplitudeEvent
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS,
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION,
      user: authGetters.USER
    }),
    isForwardAIIntegration() {
      return !!this.forwardAIIntegration && !!Object.keys(this.forwardAIIntegration).length
    },
    isBankMandateList() {
      return !!this.bankMandateList && !!this.bankMandateList.length
    },
    displayLinkBankAccount() {
      return this.$config.whitelabel.features.linkBankAccount
    }
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      storeRegistrationState: companyStateRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER
    }),
    getCompanyData() {
      return getCompany(this.companyId).then(res => {
        this.companyData = res.data
      })
    },
    init() {
      if (this.companyId) {
        return Promise.all([this.requestForwardAIIntegration(), this.requestBankMandate()]) // mixin
      }
    },
    async requestBankMandate() {
      try {
        await this.getBankMandateList(this.companyId) // FetchingData, FetchingDataCompleted, ProcessingCompleted, Failed
      } catch (err) {
        this.$toasted.show('Bank integration state is currently not available', { ...toastDefaultOptions, type: 'error', icon: 'error' })
        return
      }
      if (!this.user || !this.bankMandateStatus || this.bankMandateStatus === 'ProcessingCompleted' || this.bankMandateStatus === 'Failed') {
        return
      }
      return new Promise(resolve => {
        this.bankMandateTimerId = setTimeout(() => {
          resolve(this.requestBankMandate())
        }, 2000)
      })
    },
    unlinkBank(bankMandateId) {
      return deleteBankMandate(bankMandateId)
        .then(this.requestBankMandate)
        .catch(e => {
          console.log(e)
        })
    },
    goToNextJourneyStep() {
      this.$router.push({ name: this.nextStepName })
    },
    async setIntegrationStatusSkip(e) {
      sendBasicEventToAmplitude(this.$ma, amplitudeEvents.businessOnboarding.INTEGRATE_ACC_CONFIRM_SKIP)
      try {
        await setIntegrationStatusSkip({ companyId: this.companyId })
        await this.storeRegistrationState(this.companyId)
        return this.goToNextJourneyStep()
      } catch (e) {
        this.$toasted.clear()
        this.$toasted.show('Something went wrong. Please try again later', { ...toastDefaultOptions, icon: 'error', type: 'error' })
      }
    }
  },
  beforeMount() {
    this.init()
    this.getCompanyData()
  },
  beforeDestroy() {
    clearTimeout(this.bankMandateTimerId)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
/deep/button.btn {
  font-size: 12px;
}
</style>
